import {
	Box,
	Button,
	CircularProgress,
	LinearProgress,
	Tab,
	Tabs,
	ThemeProvider,
	Typography,
	Drawer,
} from "@mui/material";
import ContractData from "./ContractData";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsForm from "./SettingsForm";
import colors from "../constants";

const ContractsContainer = ({
	ws,
	showAlert,
	isMobile,
	password,
	generating_progress_perc,
	generating_progress_status,
	generating_contracts,
	send_contract_data,
	update_settings,
	theme,
}) => {
	const [value, setValue] = useState(0);
	const [tabs, setTabs] = useState(["Nieuw contract"]);
	const [rent_data, set_rent_data] = useState([]);

	function allyProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	function handleTabChange(event, newValue) {
		if (newValue === tabs.length) {
			let newTabs = [];
			for (let i = 0; i < tabs.length; i++) {
				newTabs.push(tabs[i]);
			}
			newTabs.push(`Nieuw contract ${tabs.length + 1}`);
			let clone = rent_data.slice();
			clone.push("new_contract");

			set_rent_data(clone);
			setTabs(newTabs);
			setValue(newValue);
		} else {
			setValue(newValue);
		}
	}

	function renterNameChange(newName, tabNum) {
		let newTabs = tabs.slice();
		newTabs[tabNum] = newName;
		setTabs(newTabs);
	}

	function rentDataChange(newRentData, tabNum) {
		let clone = rent_data.slice();
		clone[tabNum] = newRentData;
		set_rent_data(clone);
	}

	function generateContracts() {
		let clone = rent_data.slice();
		for (let i = 0; i < clone.length; i++) {
			if (clone[i].renter_name === "") {
				clone.splice(i, 1);
			}
		}

		send_contract_data(clone);
		set_rent_data(clone);
	}

	function handleTabDelete(index) {
		console.log(`Deleting tab ${index}`);

		if (value === 0) {
			setValue(tabs.length - 1);
		} else {
			setValue(0);
		}

		let clone = rent_data.slice();
		clone.splice(index, 1);
		set_rent_data(clone);

		let clone2 = tabs.slice();
		clone2.splice(index, 1);
		setTabs(clone2);
	}

	return (
		<Box
			sx={{
				border: 2,
				borderColor: "divider",
				margin: "20px auto",
				minWidth: `${isMobile ? "300px" : "500px"}`,
				maxWidth: `${isMobile ? "300px" : "1000px"}`,
				width: `${isMobile ? "300px" : "fit-content"}`,
				padding: "10px",
			}}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleTabChange}
					aria-label="basic tabs example"
					variant="scrollable"
					scrollButtons="auto">
					{tabs.map((title, i) => (
						<Tab label={title} key={i} {...allyProps(i)}></Tab>
					))}
					<Tab icon={<AddIcon />} />
				</Tabs>
			</Box>
			{tabs.map((title, i) => (
				<div role="tabpanel" hidden={value !== i} key={i}>
					<ContractData
						index={i}
						onNameChange={renterNameChange}
						onDataChange={rentDataChange}
						onTabDelete={handleTabDelete}
						key={i}
					/>
				</div>
			))}
			<Box
				sx={{
					borderTop: 1,
					borderColor: "divider",
					textAlign: "center",
				}}>
				<Button
					disabled={generating_contracts}
					variant="contained"
					sx={{ marginTop: "10px" }}
					onClick={generateContracts}>
					Contracten maken{" "}
					{generating_contracts && (
						<CircularProgress
							size={24}
							sx={{ position: "absolute", zIndex: 1 }}
						/>
					)}
				</Button>
				<br></br>

				<LinearProgress
					value={generating_progress_perc}
					variant="determinate"
					sx={{
						margin: "20px auto 0 auto",
						display: `${generating_contracts ? null : "none"}`,
					}}
				/>
				<br></br>
				<Typography
					sx={{
						display: `${generating_contracts ? null : "none"}`,
					}}>
					{generating_progress_status}
				</Typography>
			</Box>
		</Box>
	);
};

export default ContractsContainer;

import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import { colors } from "../constants";
const {
	TextField,
	Button,
	Radio,
	FormControl,
	FormLabel,
	RadioGroup,
    FormControlLabel,
} = require("@mui/material");

const ContractData = ({ index, onNameChange, onDataChange, onTabDelete }) => {
	const [extra_fields, set_extra_fields] = useState([]);
	const [renter_name, set_renter_name] = useState(null);
	const [start_date, set_start_date] = useState(null);
	const [end_date, set_end_date] = useState(null);
	const [start_time, set_start_time] = useState(null);
	const [end_time, set_end_time] = useState(null);
	const [rent_price, set_rent_price] = useState(null);
	const [sound_pack, set_sound_pack] = useState("geen");
	const [light_pack, set_light_pack] = useState("geen");

	function addExtra() {
		if (extra_fields.length === 0) {
			set_extra_fields([""]);
		} else {
			let newFields = extra_fields.slice();

			newFields.push("");
			set_extra_fields(newFields);
		}
	}

	function removeExtra(index) {
		let clone = extra_fields.slice();
		clone.splice(index, 1);
		set_extra_fields(clone);
	}

	function handleFocusChange(e) {
		if (e.target.id !== `renter_name_${index}`) {
			let regex = /[A-Za-z]+/i;
			let renterNameValue = document.getElementById(
				`renter_name_${index}`
			).value;
			if (regex.test(renterNameValue)) {
				onNameChange(renterNameValue, index);
				set_renter_name(renterNameValue);
			}
		}
	}

	function handleInputChange(e) {
		let temp_renter_name = renter_name;
		let temp_start_date = start_date;
		let temp_end_date = end_date;
		let temp_start_time = start_time;
		let temp_end_time = end_time;
		let temp_rent_price = rent_price;
		let temp_extra_fields = extra_fields.slice();
		switch (e.target.id) {
			case `renter_name_${index}`:
				temp_renter_name = e.target.value;
				set_renter_name(temp_renter_name);
				break;
			case `start_date_${index}`:
				temp_start_date = e.target.value;
				set_start_date(temp_start_date);
				break;
			case `end_date_${index}`:
				temp_end_date = e.target.value;
				set_end_date(temp_end_date);
				break;
			case `start_time_${index}`:
				temp_start_time = e.target.value;
				set_start_time(temp_start_time);
				break;
			case `end_time_${index}`:
				temp_end_time = e.target.value;
				set_end_time(temp_end_time);
				break;
			case `rent_price_${index}`:
				temp_rent_price = e.target.value;
				set_rent_price(temp_rent_price);
				break;
			default:
				let extras_id = parseInt(
					e.target.id.replace(`extra_item_${index}_`, "")
				);
				temp_extra_fields[extras_id] = e.target.value;
				set_extra_fields(temp_extra_fields);
				break;
		}

		let temp2_extra_fields = temp_extra_fields.slice();
		for (let i = 0; i < temp2_extra_fields.length; i++) {
			if (temp2_extra_fields[i] === "") {
				temp2_extra_fields.splice(i, 1);
			}
		}

		let returnData = {
			renter_name: temp_renter_name,
			start_date: temp_start_date,
			end_date: temp_end_date,
			start_time: temp_start_time,
			end_time: temp_end_time,
			rent_price: parseInt(temp_rent_price),
			extras: temp2_extra_fields,
		};

		onDataChange(returnData, index);
	}

	function handleSoundPackChange(e) {
		set_sound_pack(e.target.value)
	}

    function handleLightPackChange(e){
        set_light_pack(e.target.value)
    }

	return (
		<table
			style={{
				textAlign: "center",
				margin: "20px auto",
				width: "400px",
				borderSpacing: "5px",
			}}>
			<tbody>
				<tr>
					<td>
						<TextField
                        sx={{width: "230px"}}
							id={`renter_name_${index}`}
							label="Naam huurder"
							variant="outlined"
							onFocus={(e) => handleFocusChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>
					</td>
					<td>
						<DeleteIcon sx={{ visibility: "hidden" }} />
					</td>
				</tr>
				<tr>
					<td>
						<TextField
                        sx={{width: "230px"}}
							id={`start_date_${index}`}
							label="Begindatum"
							variant="outlined"
							onFocus={(e) => handleFocusChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>
					</td>
				</tr>
				<tr>
					<td>
						<TextField
                        sx={{width: "230px"}}
							id={`end_date_${index}`}
							label="Einddatum"
							variant="outlined"
							onFocus={(e) => handleFocusChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>
					</td>
				</tr>
				<tr>
					<td>
						<TextField
                        sx={{width: "230px"}}
							id={`start_time_${index}`}
							label="Starttijd"
							variant="outlined"
							onFocus={(e) => handleFocusChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>
					</td>
				</tr>
				<tr>
					<td>
						<TextField
                        sx={{width: "230px"}}
							id={`end_time_${index}`}
							label="Eindtijd"
							variant="outlined"
							onFocus={(e) => handleFocusChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>
					</td>
				</tr>
				<tr>
					<td>
						<TextField
                            sx={{width: "230px"}}
							id={`rent_price_${index}`}
							label="Huurprijs"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										€
									</InputAdornment>
								),
							}}
							onFocus={(e) => handleFocusChange(e)}
							onChange={(e) => handleInputChange(e)}
						/>
					</td>
				</tr>
				{extra_fields.map((value, i) => (
					<tr>
						<td>
							<TextField
								id={`extra_item_${index}_${i}`}
								label="Extra"
								variant="outlined"
								value={value === "" ? null : value}
								onFocus={(e) => handleFocusChange(e)}
								key={i}
								onChange={(e) => handleInputChange(e)}
							/>
						</td>
						<td>
							<DeleteIcon
								sx={{ cursor: "pointer" }}
								onClick={() => removeExtra(i)}
								key={i}
							/>
						</td>
					</tr>
				))}
				<tr>
					<td>
						<Button
							sx={{ color: colors.green_button }}
							onClick={addExtra}>
							<AddIcon />
							Extra huuritem
						</Button>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default ContractData;

/*

            <tr>
                <td>
                    <Button sx={{color: colors.red_text_button}} onClick={() => onTabDelete(index)}><DeleteIcon/> Contract verwijderen</Button>
                </td>
            </tr>
*/

/*

            */
